@import "../config.css";

.popupBackground {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(200,200,200,0.5);
  top: 0;
  left:0;
  z-index: 100;
}

.popupContainer {
  width: 90%;
  max-width: 400px;
  height: auto;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  text-align: center;
  margin: var(--margin-center);
  padding: var(--padding-horizontal-large);

  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: var(--shadow);
}

.popupContainer p {
  font-size: var(--font-size-large2);
  font-family: var(--font-basic);
}

.nextButton, .closeButton, .fullButton {
  margin: 0 auto;
  width: 40%;
  max-width: 150px;
  display: inline-block;
  background: var(--color-brand-1);
  color: var(--color-light-shades-1);
  border-radius: var(--border-radius);
  font-size: var(--font-size-med);
  font-family: var(--font-basic);
  font-weight: var(--font-weight-med);
  border: none;
  cursor: pointer;
  height: 50px;
}

.fullButton {
  width: 90%;
  height: 60px;
  max-width: 300px;
  display: block;
  margin-top: 20px;
}

.closeButton {
  background: transparent;
  color: var(--color-dark-shades-2);
  border: 1px solid var(--color-dark-shades-3);
}

.textField {
  width: 90%;
  max-width: 300px;
  padding: 20px;
  box-sizing: border-box;
  font-size: var(--font-size-med);
  font-family: var(--font-basic);
  color: var(--color-dark-shades-1);
  margin-top: var(--margin-vertical-small);
  border: none;
  border-radius: var(--border-radius);
  background: var(--color-light-shades-1);
}

.buttonBlock {
  display: flex;
  justify-content: space-around;
}
