@import "../config.css";

.siteContainer {
    width: 100%;
    margin: var(--margin-center);
    padding: 60px;
    box-sizing: border-box;
}

.input {
    width:100%;
    max-width: 500px;
    font-family: var(--font-basic);
    font-size:var(--font-size-med);
    margin-top:20px;
    display: flex;
    flex-wrap:wrap;
    width:100%;
}

.input * {
    width:100%;
}

.input input{
    padding: 30px 35px;
    border-radius:5px;
    border:none;
    width:100%;
    margin:0px;
    font-size: var(--font-size-large);
    font-family: var(--font-header);
    box-shadow: var(--shadow);
}

.input p {
    margin-top: 0px;
}

.highlightInput {
    background-color:var(--color-dark-accent-4);
    padding:10px;
    border-radius:5px;
    box-shadow: var(--shadow);
    font-size: var(--font-size-large);
    font-family: var(--font-basic);
}

.highlightInput h1 {
    font-size: var(--font-size-med);
    font-weight: 400;
}

.highlightInput p {
    margin-top:20px;
    font-size: 18px;
    color:var(--color-dark-shades-1);
}


.listInput {
    width:100%;
    max-width: 500px;
    font-family: var(--font-basic);
    font-size:var(--font-size-med);
    margin-top:60px;
    
}

.select {
    width:80%;
    max-width: 400px;
}

.button {
    margin:60px 0px;
    font-family: var(--font-basic);
    font-weight: var(--font-weight-med);
    font-size: var(--font-size-small);
    border: none;
    cursor: pointer;
    padding: var(--padding-block-regular);
    min-width:250px;
    background: var(--color-brand-1);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.halfBlock {
    display: flex;
    justify-content: space-between;
    width:100%;
}

.halfBlock input {
    width:32%;
}

.listItem {
    display: block;
    position: relative;
    width: 100%;
    background: var(--color-white);
    box-sizing: border-box;
    border-radius: var(--border-radius);
    border: var(--border-light);
    padding: var(--padding-block-regular);
}

.listItem h3 {
    width: 100%;
    font-size: var(--font-basic);
    font-weight: var(--font-weight-regular);
    margin: 0;
    box-sizing: border-box;
    padding-right: 40px;
}

.listItem div {
    position: absolute;
    right: 25px;
    top: 22px;
    cursor: pointer;
    margin-left: 25px;
}

.listItem div svg {
    width: 16px;
    height: 16px;
}

.listItem div svg * {
    fill: var(--color-dark-shades-2);
}

.icon {
    font-size:32px;
}







.adminContainer {
    width: 100%;
    display: inline-flex;
    height: auto;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background: var(--color-light-shades-1);
}

.navbar {
    top: 0;
    left: 0;
    position: sticky;
    position: -webkit-sticky;
    display: inline-block;
    width: 260px;
    height: 100vh;
    background: var(--color-white);
    padding: var(--body-padding);
    padding-top: 80px;
    box-sizing: border-box;
}

.navbar div {
    display: block;
    width: 100%;
    margin-top: var(--margin-vertical-large);
}

.navbar div a {
    font-family: var(--font-header);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-med);
    color: var(--color-dark-shades-1);
    text-decoration: none;
}

.navbar .active a {
    color: var(--color-brand-1) !important;
}

.navbar svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-bottom: -2px;
}

.navbar .separator {
    width: 100%;
    height: 1px;
    display: block;
    border-bottom: 1px solid var(--color-dark-shades-4);
}

.dataContainer {
    width: calc(100% - 260px);
    max-width: 1000px;
    display: block;
    height: 100vh;
    padding: 0 40px 40px 100px;
    box-sizing: border-box;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dataContainer::-webkit-scrollbar {
    display: none;
  }

.titleContainer {
    width: 100%;
    margin-top: 120px;
    margin-bottom: 60px;
    position: relative;
}

.titleContainer h3 {
    font-family: var(--font-header);
    font-size: var(--font-size-large3);
    color: var(--color-dark-shades-1);
    font-weight: var(--font-weight-med);
    letter-spacing: 1px;
}

.titleContainer button {
    position: absolute;
    padding-left: 60px;
    padding-right: 20px;
    right: 0;
    top: 0;
    height: 45px;
    background: var(--color-brand-1);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    
    font-family: var(--font-header);
    color: var(--color-white);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-light);
}

.titleContainer button svg {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
}

.tile {
    width: 100%;
    min-height: 70px;
    padding: var(--padding-block-regular);
    margin-top: var(--margin-vertical-small);
    background: var(--color-white);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    display: block;
    position: relative;
}

.siteTile {
    padding-left: 120px;
}

.tile p {
    width: 250px;
    display: inline-block;
    padding: 0;
    margin: 0;
    font-family: var(--font-basic);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-1);
}

.tile .header {
    color: var(--color-brand-1);
}

.tile button {
    position: absolute;
    right: 10px;
    top: 5px;
    width: 60px;
    height: 60px;
    background: var(--color-light-shades-1);
    border: var(--border-light);
    border-radius: var(--border-radius);
    cursor: pointer;
}

.tile button svg {
    width: var(--icon-size-mid);
    height: var(--icon-size-mid)
}

.tile .deleteButton {
    right: 80px;
}

.tile .checkboxContainer {
    width: 70px;
    height: 100%;
    border-right: 1px solid var(--color-dark-shades-4);
    position: absolute;
    top: 0;
    left: 0;
}

.tile .checkboxContainer .checkbox {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 20px;
    left: 19px;
    background: var(--color-light-shades-1);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-dark-shades-4);
    cursor: pointer;
}

.tile .checkboxContainer .checkbox svg {
    display: none;
}

.checked {
    background: var(--color-brand-1) !important;
}

.checked svg {
    top: 5px;
    left: 6px;
    width: var(--icon-size-mid);
    height: var(--icon-size-mid);
    position: absolute;
    display: block !important;
}

.chooseAllContainer {
    width: 100%;
    padding-left: 19px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.chooseAllContainer .checkbox {
    width: 30px;
    height: 30px;
    background: var(--color-white);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-dark-shades-4);
    cursor: pointer;
    display: inline-block;
    margin-top: -25px;
    position: relative;
}

.chooseAllContainer p {
    font-family: var(--font-basic);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-light);
    color: var(--color-dark-shades-1);
    display: inline-block;
    margin: 0;
    margin-left: 10px;
    vertical-align: bottom;
    margin-bottom: 5px;
}

.chooseAllContainer button {
    font-family: var(--font-basic);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    color: var(--color-danger);
    display: inline-block;
    margin-left: 30px;
    vertical-align: bottom;
    margin-bottom: 5px;
    border: none;
    background: transparent;
    cursor: pointer;
}

.noSitesDisclaimer {
    margin-top: 100px;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-1);
}