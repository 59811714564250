@import './config.css';

.container {
    width: 100%;
    height: 100%;
    position: relative;
}

.loginFormContainer {
    background: var(--color-white);
    width: 90%;
    max-width: 400px;
    height: auto;
    margin: var(--margin-center);
    padding: var(--margin-vertical-large);
    border: var(--border-light);
    border-radius: var(--border-radius-large);
    text-align: center;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: var(--shadow);
}

.loading {
    opacity: 0.5;
}

.mainHeader {
    font-size: var(--font-size-large2);
    font-family: var(--font-header);
    font-weight: var(--font-weight-med);
    color: var(--color-dark-shades-2);
}

.logo {
    font-size: var(--font-size-large2);
    font-family: var(--font-header);
    font-weight: var(--font-weight-med);
    color: var(--color-brand-1);
    margin-top: var(--margin-vertical-med);
    margin-bottom: var(--margin-vertical-med);
}

.inputContainer {
    width: 100%;
    text-align: left;
    margin-top: var(--margin-vertical-large);
}

.loginFormContainer label {
    font-size: var(--font-size-med);
    font-family: var(--font-header);
    color: var(--color-dark-shades-1);
    font-weight: var(--font-weight-med);
}

.loginFormContainer input {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-size: var(--font-size-med);
    font-family: var(--font-basic);
    color: var(--color-dark-shades-1);
    margin-top: var(--margin-vertical-small);
    border: none;
    border-radius: 7px;
    background: var(--color-light-shades-1);
}
.loginFormContainer input:focus {
    outline: 2px solid var(--color-brand-1);
}


.loginFormContainer button {
    width: 100%;
    height: 60px;
    font-size: var(--font-size-large);
    background: var(--color-brand-1);
    font-family: var(--font-basic);
    color: var(--color-light-shades-1);
    border: none;
    border-radius: 7px;
    margin-top: var(--margin-vertical-med);
    cursor: pointer;
}