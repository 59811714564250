@import '../config.css';


.siteInfo {
    width:auto;
    background-color: var(--color-white);
    padding:40px;
    margin-top:40px;
    display: flex;
    flex-wrap:wrap;
}

.siteInfo div {
    width:30%;
}

.siteInfo div p {
    font-family: var(--font-basic);
    font-size: var(--font-size-large2);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-2);
    text-transform: capitalize;
}

.siteInfo div .description {
    font-family: var(--font-header);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .siteInfo {
        width:100%;
        box-sizing: border-box;
        background-color: transparent;
        padding: var(--padding-block-small);
        margin:0;
    }
    .siteInfo div {
        width: 50%;
    }
    .siteInfo div p, .siteInfo div .description {
        font-size: var(--font-size-small);
    }
}
