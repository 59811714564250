@import '../config.css';

.filterContainer {
    position: absolute;
    top: 20px;
    width: 80%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 4;
}

.filterBlock {
    width: 100%;
    height: 75px;
    padding: 0;
    padding-top: var(--padding-horizontal-small);
    padding-bottom: var(--padding-horizontal-small);
    box-sizing: border-box;
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius-large);
}

.filter {
    width: calc( 100%/3 - 25px );
    height: auto;
    padding: 0 30px 0 30px;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    cursor: pointer;
    position: relative;
    border-right: 0.5px solid var(--color-dark-shades-4);
}

.openFilter {
    display: inline-block;
    height: auto;
    z-index: 6;
    background: var(--color-white);
    box-sizing: border-box;
    border-radius: var(--border-radius);
}

.filter:nth-child(3), .filter:nth-child(4) {
    border-right: none;
}

.selectInput {
    width: 100%;
    height: 100%;
    position: relative;
    padding: none;
    margin: none;
}

.selectInput p {
    padding: 0;
    margin: 0;
    color: var(--color-dark-shades-1);
    max-height: 100%;
    overflow: hidden;
}

.selectInput .filterLabel {
    text-transform: uppercase;
    font-family: var(--font-header);
    font-size: var(--font-size-small-phone);
    font-weight: var(--font-weight-med);
    letter-spacing: 1.25px;
}

.selectInput .filterValue {
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-light);
    height: 26px;
    margin-top: 5px;
    letter-spacing: 0.5px;
}

.typeSelect {
    padding:10px;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-light);
    letter-spacing: 0.5px;
}

.options {
    position: absolute;
    z-index: 6;
    top: 70px;
    left: 0;
    width: 100%;
    height: auto;
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: var(--border-radius-large);
    box-shadow: var(--shadow);
    background: var(--color-white);
}

.open {
    display: block;
}
.closed {
    display:none;
}

.options div {
    padding: 10px 20px 10px 20px;
    width: 100%;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    box-sizing: border-box;
    overflow: hidden;
}

.options div:hover {
    background: var(--color-light-shades-2);
}

.selectedOption {
    color: var(--color-brand-1);
    font-weight: var(--font-weight-bold) !important;
}

.selectAllOption {
    border-bottom: 1px solid var(--color-light-accent-1);
}

.searchFilter {
    padding: 0;
    max-width: none;
    width: 65px;
    height: 65px;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 5px;
    text-align: center;
    border-radius: var(--border-radius-large);
    background: var(--color-brand-1);
}

.filterIcon svg {
    margin: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    width: var(--icon-size-large);
    height: var(--icon-size-large);
    border-radius: var(--border-radius);
}

.filterIcon svg * {
    fill: var(--color-white);
}

.searchInputBlock svg {
    margin: 0;
    position: absolute;
    top: 25px;
    left: 50px;
    width: var(--icon-size-large);
    height: var(--icon-size-large);
    border-radius: var(--border-radius);
}

.searchInputBlock svg * {
    fill: var(--color-brand-1);
}

.searchInputBlock {
    width: 85vw;
    max-width: 1000px;
    display: block;
    position: fixed;
    box-sizing: border-box;
    height: 80px;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 75px;
    padding: var(--padding-block-regular);
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius-large);
    z-index: 3;
}

.searchInputBlock div {
    display: inline-block;
    margin-top: 5px;
}

.searchInputBlock div * {
    fill: blue;
}

.searchInputBlock input {
    outline: none;
    border: none;
    padding: 0 10px 0 10px;
    margin-top: 5px;
    box-sizing: border-box;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    color: var(--color-dark-shades-2);
    width: calc(100% - 2*var(--padding-horizontal-large) - 10px);
    display: inline-block;
    background: transparent;
}

.searchInputBlock button {
    border: none;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    color: var(--color-white);
    width: 65px;
    height: 65px;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 5px;
    text-align: center;
    border-radius: var(--border-radius-large);
    background: var(--color-brand-1);
}
.chosenFilterBlock {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: var(--body-padding);
    margin-bottom: var(--margin-vertical-small);
}
.item {
    display: inline-block;
    width: auto;
    height: auto;
    background: var(--color-white);
    padding: var(--padding-block-small);
    border-radius: var(--border-radius);
    margin-right: var(--margin-vertical-med);
}

.chosenFilterBlock p {
    font-size: var(--font-size-med);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-light);
    color: var(--color-dark-shades-2);
    display: inline-block;
    margin: 0;
}

.chosenFilterBlock .value {
    font-weight: var(--font-weight-med);
    font-size: var(--font-size-large);
    margin-right: 10px;
    margin-left: 5px;
}

.chosenFilterBlock .icon * {
    width: var(--icon-size-small);
    height: var(--icon-size-small);
    fill: var(--color-dark-shades-1);
    margin-bottom: -2px;
}

.chosenFilterBlock .deleteAll {
    width: auto;
    background: transparent;
    border: none;
    margin-top: var(--margin-vertical-med);
    margin-left: var(--margin-vertical-small);
    color: var(--color-dark-shades-2);
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-med);
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .filterContainer {
        top: 10px;
        width: calc(100% - 20px);
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 4;
    }
    
    .filterBlock {
        width: 100%;
        height: 60px;
        border-radius: var(--border-radius);
        padding-left: 5px;
    }
    
    .filter {
        width: calc( 100%/3 - 10px );
        padding: 0 5px 0 5px;
    }
    
    .selectInput .filterLabel {
        text-transform: uppercase;
        font-family: var(--font-header);
        font-size: 10px;
        letter-spacing: 0.75px;
    }
    
    .selectInput .filterValue {
        font-size: 13px;
        height: 20px;
        letter-spacing: 0.25px;
        font-weight: var(--font-weight-regular);
    }

    .options {
        position: fixed;
        top: 100px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: calc(100% - 20px);
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: var(--border-radius);
    }
    
    .options div {
        padding: 10px;
        font-size: var(--font-size-small-phone);
    }

    .searchFilter {
        width: 50px;
        height: 50px;
        right: 5px;
        border-radius: var(--border-radius);
    }

    .filterIcon svg {
        top: 13px;
        right: 14px;
        width: var(--icon-size-large);
        height: var(--icon-size-large);
        border-radius: var(--border-radius);
    }

    .searchInputBlock {
        width: calc(100% - 20px);
        height: 60px;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 5px 10px 5px 10px;
        background: var(--color-white);
    }

    .searchInputBlock div {
        display: inline-block;
        margin-top: 5px;
    }

    .searchInputBlock input {
        font-size: var(--font-size-small-phone);
        width: calc(100% - 60px);
        margin-top: 15px;
    }

    .searchInputBlock button {
        font-size: var(--font-size-small-phone);
        width: 50px;
        height: 50px;
        right: 5px;
        top: 5px;
        border-radius: var(--border-radius);
    }

    .searchInputBlock svg {
        top: 15px;
        left: 15px;
        width: var(--icon-size-large);
        height: var(--icon-size-large);
    }
    

  }