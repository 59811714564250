@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Urbanist:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);
body {
  padding: 0;
  margin: 0;
}

:root {
  --color-dark-shades-1: rgba(34, 33, 47, 1);
  --color-dark-shades-2: rgba(34, 33, 47, 0.75);
  --color-dark-shades-3: rgba(34, 33, 47, 0.5);
  --color-dark-shades-4: rgba(34, 33, 47, 0.25);

  --color-light-shades-1: rgba(240, 244, 245, 1);
  --color-light-shades-2: rgba(240, 244, 245, 0.75);
  --color-light-shades-3: rgba(240, 244, 245, 0.5);
  --color-light-shades-4: rgba(240, 244, 245, 0.25);

  --color-brand-1: rgba(46, 45, 166, 1);
  --color-brand-2: rgba(46, 45, 166, 0.75);
  --color-brand-3: rgba(46, 45, 166, 0.5);
  --color-brand-4: rgba(46, 45, 166, 0.25);

  --color-light-accent-1: rgba(148, 172, 211, 1);
  --color-light-accent-2: rgba(148, 172, 211, 0.75);
  --color-light-accent-3: rgba(148, 172, 211, 0.5);
  --color-light-accent-4: rgba(148, 172, 211, 0.25);

  --color-dark-accent-1: rgba(101, 122, 183, 1);
  --color-dark-accent-2: rgba(101, 122, 183, 0.75);
  --color-dark-accent-3: rgba(101, 122, 183, 0.5);
  --color-dark-accent-4: rgba(101, 122, 183, 0.25);

  --color-white: #fff;
  --color-background: rgb(240, 244, 245);
  --color-background-dark: rgb(229, 233, 236);
  --color-warning: #c08432;
  --color-danger: #A02920;
  --color-success: #43946a;
  --color-shadow: rgba(34, 33, 47, 0.1);

  --shadow: 0 6px 20px 0 rgba(34, 33, 47, 0.1);
  --border-radius: 3px;
  --border-radius-large: 15px;
  --border-light: 1px solid rgba(240, 244, 245, 1);

  --body-padding: 0 40px 0 40px;
  --padding-block-regular: 20px 30px 20px 30px;
  --padding-block-small: 10px 15px 10px 15px;
  --padding-horizontal-large: 40px;
  --padding-horizontal-regular: 30px;
  --padding-horizontal-small: 15px;
  --margin-vertical-small: 10px;
  --margin-vertical-med: 30px;
  --margin-vertical-large: 50px;
  --margin-center: 0 auto;

  --font-basic: "Red Hat Text", sans;
  --font-header: "Urbanist", sans-serif;
  --font-size-xlarge: 28px;
  --font-size-large3: 26px;
  --font-size-large2: 24px;
  --font-size-large: 22px;
  --font-size-med: 20px;
  --font-size-small: 18px;
  --font-size-small-phone: 14px;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-med: 500;
  --font-weight-bold: 600;
  --letter-spacing-small: 0.1px;
  --text-shadow: 2px 2px 4px rgba(34, 33, 47, 0.2);

  --icon-size-small: 14px;
  --icon-size-mid: 18px;
  --icon-size-large: 24px;
}

@-webkit-keyframes phase_inspectAnim__oOKR6 {
    from {bottom: -500px}
    to {bottom: 0}
}

@keyframes phase_inspectAnim__oOKR6 {
    from {bottom: -500px}
    to {bottom: 0}
}

.phase_siteContainer__2Hxt0 {
    width: 100vw;
    height: 70vh;
    overflow-y: scroll;
    background: var(--color-background);
    z-index: 10;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -6px 4px 0 var(--color-shadow);
    -webkit-animation: phase_inspectAnim__oOKR6 0.5s;
            animation: phase_inspectAnim__oOKR6 0.5s;
}

.phase_dataContainer__2BU4m {
    width: 100%;
    padding: var(--padding-block-regular);
    padding-top: 80px;
    box-sizing: border-box;
}

.phase_phase__2uEDo {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-block-regular);
    font-family: var(--font-basic);
    border-radius: var(--border-radius);
    position: relative;
}

.phase_activePhase__1Xiam {
    background-color: var(--color-white);
    box-shadow: var(--shadow);
}

.phase_allCompletedPhase__LE8u3 {
    background-color: var(--color-brand-1);
}

.phase_allCompletedPhase__LE8u3 * {
    color: var(--color-light-shades-1) !important;
}


.phase_phase__2uEDo .phase_name__35jyH {
    font-family: var(--font-header);
    font-size: var(--font-size-large3);
    font-weight: var(--font-weight-med);
    color: var(--color-brand-1);
}

.phase_phase__2uEDo  .phase_description__1q-ph {
    font-size: var(--font-size-small);
    color: var(--color-dark-shades-2);
    font-weight: var(--font-weight-med);
}

.phase_actionButton__mItFX {
    padding: 0;
    background: transparent;
    color: var(--color-dark-shades-2);
    font-size: var(--font-size-med);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-med);
    border: none;
    cursor: pointer;
}

.phase_activePhase__1Xiam .phase_actionButton__mItFX {
    padding: var(--padding-block-regular);
    background: var(--color-brand-1);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.phase_actionButton__mItFX svg {
    width: 20px;
    margin-right: 10px;
    margin-bottom: -2px;
    fill: var(--color-light-shades-1);
}

.phase_commentsContainer__yl5ya {
    display: flex;
    flex-direction: column-reverse;
}

.phase_commentWrapper__l2Kdc {
    width: 100%;
    display: block;
    font-family: var(--font-basic);
    color: var(--color-dark-shades-1);
    font-size: var(--font-size-med);
}

.phase_comment__2LwBe {
    width: auto;
    display: inline-block;
    background-color:var(--color-white);
    box-shadow: var(--shadow);
    padding: var(--padding-block-small);
    box-sizing: border-box;
    margin-bottom: var(--margin-vertical-med);
    border-radius: var(--border-radius);
}

.phase_commentWrapper__l2Kdc .phase_sender__2T2zs {
    font-family: var(--font-header);
    font-weight: var(--font-weight-med);
    color: var(--color-dark-shades-2);
}

.phase_comment__2LwBe .phase_text__34Pu9 {
    display: block;
    width: auto;
}

.phase_comment__2LwBe .phase_date__tRFw5 {
    color: var(--color-dark-shades-2);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-med);
}

.phase_commentInfoTitle__CgTMj {
    font-family: var(--font-header);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-large);
    margin-bottom: var(--margin-vertical-med);
    margin-top: calc(2*var(--margin-vertical-large));
    color: var(--color-dark-shades-2);
}

.phase_textAreaContainer__pmpAZ {
    width:100%;
    background-color:var(--color-white);
    box-shadow: var(--shadow);
    padding: var(--padding-block-regular);
    padding-left: 40px;
    box-sizing: border-box;
    margin-bottom: var(--margin-vertical-med);
    border-radius: var(--border-radius);
    position: relative;
    height: 80px;
}

textarea {
    border:none;
    width: calc(100% - 60px);
    height: 50px;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    font-size: var(--font-size-large);
    font-family: var(--font-header);
    resize: none;
}

textarea:focus {
    outline: none;
}

.phase_textAreaContainer__pmpAZ button {
    border: none;
    color: var(--color-light-shades-1);
    width: 60px;
    height: 60px;
    top: 10px;
    right: 20px;
    position: absolute;
    border-radius: var(--border-radius);
    background-color: var(--color-brand-1);
    cursor: pointer;
}

.phase_textAreaContainer__pmpAZ button svg {
    fill: var(--color-light-shades-1);
    width: 28px;
    height: 28px;
}

.phase_tabButtons__1p632 {
    width: 100%;
    margin-bottom: var(--margin-vertical-med);
}

.phase_tabButtons__1p632 button {
    width: auto;
    padding: 0 30px 0 30px;
    box-sizing: border-box;
    height: 50px;
    border-radius: 50px;
    background: transparent;
    color: var(--color-dark-shades-2);
    border: none;
    margin-right: var(--margin-vertical-large);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-med);
    font-family: var(--font-basic);
    cursor: pointer;
}

.phase_tabButtons__1p632 button * {
    display: inline;
    padding: 0;
}

.phase_tabButtons__1p632 button span svg {
    width: 20px;
    height: 20px;
    margin-bottom: -5px;
    margin-right: 10px;
}

.phase_tabButtons__1p632 .phase_active__1o6pv {
    background: var(--color-dark-shades-2);
    color: var(--color-light-shades-1);
}

.phase_tabButtons__1p632 span * {
    fill: var(--color-dark-shades-1);
}

.phase_tabButtons__1p632 .phase_active__1o6pv span * {
    fill: var(--color-white);
}

.phase_expandButton__9ScQ- {
    width: auto;
    position: absolute;
    top: -25px;
    left: 40px;
    height: 50px;
    padding: var(--padding-block-small);
    border-radius: var(--border-radius);
    background: var(--color-white);
    color: var(--color-brand-1);
    border: none;
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-basic);
    box-shadow: var(--shadow);
    cursor: pointer;
}

.phase_expandButton__9ScQ- * {
    display: inline;
}

.phase_expandButton__9ScQ- svg {
    width: 16px;
    height: 16px;
    margin-bottom: -5px;
    margin-right: 20px;
}

.phase_blockedContainer__1R_ns {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    box-sizing: border-box;
    display: block;
    margin-top: 40px;
    background: var(--color-white);
    text-align: center;
}

.phase_blockedContainer__1R_ns p {
    font-size: var(--font-size-small);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-1);
    text-align: center;
    display: block;
}

.phase_blockedContainer__1R_ns button {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-top: 40px;
    font-size: var(--font-size-small-phone);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-med);
    border: none;
    cursor: pointer;
    height: 50px;
    background: var(--color-brand-1);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.phase_blockedContainer__1R_ns svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
}
.phase_blockedContainer__1R_ns svg * {
    fill: var(--color-danger);
}

.phase_markBlockedContainer__1lFtA {
    width: calc(100% - 20px);
    margin: 0 auto;
    display: block;
    margin-top: 40px;
}

.phase_markBlockedContainer__1lFtA button {
    padding: 0;
    font-size: var(--font-size-small);
    font-size: var(--font-size-small-phone);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-regular);
    border: none;
    cursor: pointer;
    
    width: 200px;
    height: 50px;
    background: var(--color-dark-shades-2);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.phase_markBlockedContainer__1lFtA button * {
    display: inline;
}

.phase_markBlockedContainer__1lFtA button svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-bottom: -4px;
}

.phase_markBlockedContainer__1lFtA p {
    padding: 0;
    font-size: var(--font-size-small-phone);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-2);
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .phase_siteContainer__2Hxt0 {
        width: 100vw;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .phase_dataContainer__2BU4m {
        padding: 0;
    }

    .phase_expandButton__9ScQ- {
        display: none;
    }

    .phase_tabButtons__1p632 button {
        width: 50%;
        padding: 0;
        box-sizing: border-box;
        border-radius: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        margin: 0;
        font-size: var(--font-size-small-phone);
    }
    .phase_tabButtons__1p632 .phase_left__NeNFj {
        border-top-right-radius: 0;
    }
    .phase_tabButtons__1p632 .phase_right__2sJAz {
        border-top-left-radius: 0;
    }
    .phase_phase__2uEDo {
        padding: var(--padding-block-small);
        border-radius: 0;
    }
    .phase_phase__2uEDo .phase_name__35jyH {
        font-size: var(--font-size-large);
    }
    .phase_phase__2uEDo  .phase_description__1q-ph {
        font-size: var(--font-size-small-phone);
    }
    .phase_actionButton__mItFX {
        font-size: var(--font-size-small-phone);
        position: absolute;
        top: 20px;
        right: 10px;
    }
    .phase_activePhase__1Xiam .phase_actionButton__mItFX {
        padding: var(--padding-block-regular);
        width: 100%;
        position: static;
    }
    .phase_actionButton__mItFX svg {
        width: 15px;
        margin-right: 5px;
        margin-bottom: -3px;
    }
    .phase_commentAreaContainer__3nCcX {
        width: 100%;
        padding: var(--padding-block-small);
        box-sizing: border-box;
    }
    .phase_commentInfoTitle__CgTMj {
        font-size: var(--font-size-small);
        margin-top: var(--margin-vertical-large);
    }
    .phase_commentWrapper__l2Kdc {
        font-size: var(--font-size-small);
    }
    .phase_comment__2LwBe {
        width: 100%;
        display: block;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
    .phase_comment__2LwBe .phase_date__tRFw5 {
        font-size: var(--font-size-small-phone);
    }
    .phase_textAreaContainer__pmpAZ {
        padding: var(--padding-block-small);
        padding-left: 10px;
        height: 60px;
    }
    
    textarea {
        width: calc(100% - 40px);
        height: 40px;
        margin-top: 10px;
        font-size: 16px;
    }
    
    textarea:focus {
        outline: none;
    }
    
    .phase_textAreaContainer__pmpAZ button {
        border: none;
        color: var(--color-light-shades-1);
        width: 60px;
        height: 60px;
        top: 10px;
        right: 20px;
        position: absolute;
        border-radius: var(--border-radius);
        background-color: var(--color-brand-1);
        cursor: pointer;
    }

    .phase_textAreaContainer__pmpAZ button {
        width: 60px;
        height: 60px;
        top: 0;
        right: 0;
    }

    .phase_textAreaContainer__pmpAZ button svg {
        width: 20px;
        height: 20px;
    }

    .phase_blockedContainer__1R_ns {
        max-width: none;
    }

    .phase_blockedContainer__1R_ns p {
        font-size: var(--font-size-small-phone);
    }
    
}
.info_siteInfo__Sx0JX {
    width:auto;
    background-color: var(--color-white);
    padding:40px;
    margin-top:40px;
    display: flex;
    flex-wrap:wrap;
}

.info_siteInfo__Sx0JX div {
    width:30%;
}

.info_siteInfo__Sx0JX div p {
    font-family: var(--font-basic);
    font-size: var(--font-size-large2);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-2);
    text-transform: capitalize;
}

.info_siteInfo__Sx0JX div .info_description__3VY3t {
    font-family: var(--font-header);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .info_siteInfo__Sx0JX {
        width:100%;
        box-sizing: border-box;
        background-color: transparent;
        padding: var(--padding-block-small);
        margin:0;
    }
    .info_siteInfo__Sx0JX div {
        width: 50%;
    }
    .info_siteInfo__Sx0JX div p, .info_siteInfo__Sx0JX div .info_description__3VY3t {
        font-size: var(--font-size-small);
    }
}

.popup_popupBackground__1TeX8 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(200,200,200,0.5);
  top: 0;
  left:0;
  z-index: 100;
}

.popup_popupContainer__1sO5_ {
  width: 90%;
  max-width: 400px;
  height: auto;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  text-align: center;
  margin: var(--margin-center);
  padding: var(--padding-horizontal-large);

  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: var(--shadow);
}

.popup_popupContainer__1sO5_ p {
  font-size: var(--font-size-large2);
  font-family: var(--font-basic);
}

.popup_nextButton__1ztn9, .popup_closeButton__2uvyh, .popup_fullButton__moWPF {
  margin: 0 auto;
  width: 40%;
  max-width: 150px;
  display: inline-block;
  background: var(--color-brand-1);
  color: var(--color-light-shades-1);
  border-radius: var(--border-radius);
  font-size: var(--font-size-med);
  font-family: var(--font-basic);
  font-weight: var(--font-weight-med);
  border: none;
  cursor: pointer;
  height: 50px;
}

.popup_fullButton__moWPF {
  width: 90%;
  height: 60px;
  max-width: 300px;
  display: block;
  margin-top: 20px;
}

.popup_closeButton__2uvyh {
  background: transparent;
  color: var(--color-dark-shades-2);
  border: 1px solid var(--color-dark-shades-3);
}

.popup_textField__22Wk_ {
  width: 90%;
  max-width: 300px;
  padding: 20px;
  box-sizing: border-box;
  font-size: var(--font-size-med);
  font-family: var(--font-basic);
  color: var(--color-dark-shades-1);
  margin-top: var(--margin-vertical-small);
  border: none;
  border-radius: var(--border-radius);
  background: var(--color-light-shades-1);
}

.popup_buttonBlock__1afNi {
  display: flex;
  justify-content: space-around;
}

.map_mapContainer__20Q4O {
  width: 100%;
  height: 100%;
  position: relative;
}

.map_mapBlock__3D6Gl {
  position: fixed;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  top: 0;
  left: 0;
  background: var(--color-white);
  z-index: 2;
}

.map_inspectPageMapBlock__1EK2R > div {
  width: 100% !important;
}

.map_inspectPageMapBlock__1EK2R .map_siteName__2z9sQ {
  position: absolute;
  top: 70px;
  left: 110px;
  font-weight: var(--font-weight-bold);
  color: var(--color-dark-shades-2);
  text-shadow: 0 2px 4px var(--color-light-shades-3);
  font-size: 36px;
  font-family: var(--font-header);
  z-index: 4;
  margin: 0;
}

.map_goBackBlock__2d_VC {
  position: absolute;
  top: 55px;
  left: 40px;
  z-index: 10;
}

.map_goBackBlock__2d_VC .map_backButton__3SUkE {
  background-color: var(--color-white);
  border-radius: 100%;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  z-index: 4;
}

.map_goBackBlock__2d_VC h3 {
  display: inline-block;
  margin-left: 10px;
  font-weight: var(--font-weight-bold);
  color: var(--color-dark-shades-2);
  text-shadow: 0 2px 4px var(--color-light-shades-3);
  font-size: 26px;
  font-family: var(--font-header);
}

.map_marker__2JR6m {
  position: absolute;
  border-radius: 50%;
  background-image: url("http:/icons/marker-screen.svg");
  width: 60px;
  height: 60px;
  background-size: 100%;
  z-index: 3;
}

.map_marker__2JR6m h3 {
  font-size: var(--font-size-med);
  font-family: var(--font-basic);
  font-weight: var(--font-weight-med);
  color: var(--color-dark-shades-2);
  text-shadow: var(--text-shadow);
  margin-top: 60px;
  text-align: center;
}

.map_popupContainer__1GBrM {
  z-index: 5;
  border-radius: var(--border-radius);
}

.map_popup__gYGTw {
  width: auto;
  max-width: 250px;
  text-align: left;
  position: relative;
  padding: 10px 40px 10px 40px;
}

.map_popup__gYGTw * {
  padding: 0;
  margin: 0;
}

.map_popup__gYGTw h3 {
  font-size: 20px;
  color: var(--color-dark-shades-2);
  font-family: var(--font-header);
  font-weight: var(--font-weight-bold);
  margin-bottom: 30px;
}

.map_popup__gYGTw div {
  display: block;
  width: auto;
  margin-bottom: 20px;
}

.map_popup__gYGTw div span {
  display: inline-block;
  margin-right: 15px;
}

.map_popup__gYGTw div span svg {
  width: 20px;
  height: 20px;
  margin-bottom: -5px;
}

.map_popup__gYGTw div p {
  display: inline;
  font-family: var(--font-basic);
  font-size: var(--font-size-med);
  color: var(--color-dark-shades-2);
}

.map_popup__gYGTw button {
  width: 100%;
  font-family: var(--font-basic);
  font-size: var(--font-size-med);
  color: var(--color-light-shades-1);
  background: var(--color-brand-1);
  padding: var(--padding-block-small);
  border: none;
  border-radius: var(--border-radius);
  margin-top: 10px;
}

.map_hidden__19A2M {
  display: none;
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .map_goBackBlock__2d_VC {
      top: 25px;
      left: 20px;
    }
    .map_goBackBlock__2d_VC h3  {
      font-size: var(--font-size-large3);
    }

    .map_goBackBlock__2d_VC .map_backButton__3SUkE {
      font-size: var(--font-size-large);
      width: 45px;
      height: 45px;
    }
}
.filters_filterContainer__RHY-u {
    position: absolute;
    top: 20px;
    width: 80%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 4;
}

.filters_filterBlock__3AZPN {
    width: 100%;
    height: 75px;
    padding: 0;
    padding-top: var(--padding-horizontal-small);
    padding-bottom: var(--padding-horizontal-small);
    box-sizing: border-box;
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius-large);
}

.filters_filter__2Qvc8 {
    width: calc( 100%/3 - 25px );
    height: auto;
    padding: 0 30px 0 30px;
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;
    cursor: pointer;
    position: relative;
    border-right: 0.5px solid var(--color-dark-shades-4);
}

.filters_openFilter__2MHjK {
    display: inline-block;
    height: auto;
    z-index: 6;
    background: var(--color-white);
    box-sizing: border-box;
    border-radius: var(--border-radius);
}

.filters_filter__2Qvc8:nth-child(3), .filters_filter__2Qvc8:nth-child(4) {
    border-right: none;
}

.filters_selectInput__2krmf {
    width: 100%;
    height: 100%;
    position: relative;
    padding: none;
    margin: none;
}

.filters_selectInput__2krmf p {
    padding: 0;
    margin: 0;
    color: var(--color-dark-shades-1);
    max-height: 100%;
    overflow: hidden;
}

.filters_selectInput__2krmf .filters_filterLabel__16hlE {
    text-transform: uppercase;
    font-family: var(--font-header);
    font-size: var(--font-size-small-phone);
    font-weight: var(--font-weight-med);
    letter-spacing: 1.25px;
}

.filters_selectInput__2krmf .filters_filterValue__XocW0 {
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-light);
    height: 26px;
    margin-top: 5px;
    letter-spacing: 0.5px;
}

.filters_typeSelect__1brYK {
    padding:10px;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-light);
    letter-spacing: 0.5px;
}

.filters_options__12jbY {
    position: absolute;
    z-index: 6;
    top: 70px;
    left: 0;
    width: 100%;
    height: auto;
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: var(--border-radius-large);
    box-shadow: var(--shadow);
    background: var(--color-white);
}

.filters_open__QEe8J {
    display: block;
}
.filters_closed__2wkVS {
    display:none;
}

.filters_options__12jbY div {
    padding: 10px 20px 10px 20px;
    width: 100%;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    box-sizing: border-box;
    overflow: hidden;
}

.filters_options__12jbY div:hover {
    background: var(--color-light-shades-2);
}

.filters_selectedOption__2MDSo {
    color: var(--color-brand-1);
    font-weight: var(--font-weight-bold) !important;
}

.filters_selectAllOption__3FkfR {
    border-bottom: 1px solid var(--color-light-accent-1);
}

.filters_searchFilter__Lp0QD {
    padding: 0;
    max-width: none;
    width: 65px;
    height: 65px;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 5px;
    text-align: center;
    border-radius: var(--border-radius-large);
    background: var(--color-brand-1);
}

.filters_filterIcon__FyfKr svg {
    margin: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    width: var(--icon-size-large);
    height: var(--icon-size-large);
    border-radius: var(--border-radius);
}

.filters_filterIcon__FyfKr svg * {
    fill: var(--color-white);
}

.filters_searchInputBlock__1-mWZ svg {
    margin: 0;
    position: absolute;
    top: 25px;
    left: 50px;
    width: var(--icon-size-large);
    height: var(--icon-size-large);
    border-radius: var(--border-radius);
}

.filters_searchInputBlock__1-mWZ svg * {
    fill: var(--color-brand-1);
}

.filters_searchInputBlock__1-mWZ {
    width: 85vw;
    max-width: 1000px;
    display: block;
    position: fixed;
    box-sizing: border-box;
    height: 80px;
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 75px;
    padding: var(--padding-block-regular);
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius-large);
    z-index: 3;
}

.filters_searchInputBlock__1-mWZ div {
    display: inline-block;
    margin-top: 5px;
}

.filters_searchInputBlock__1-mWZ div * {
    fill: blue;
}

.filters_searchInputBlock__1-mWZ input {
    outline: none;
    border: none;
    padding: 0 10px 0 10px;
    margin-top: 5px;
    box-sizing: border-box;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    color: var(--color-dark-shades-2);
    width: calc(100% - 2*var(--padding-horizontal-large) - 10px);
    display: inline-block;
    background: transparent;
}

.filters_searchInputBlock__1-mWZ button {
    border: none;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    color: var(--color-white);
    width: 65px;
    height: 65px;
    margin: 0;
    position: absolute;
    right: 10px;
    top: 5px;
    text-align: center;
    border-radius: var(--border-radius-large);
    background: var(--color-brand-1);
}
.filters_chosenFilterBlock__1_BnH {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: var(--body-padding);
    margin-bottom: var(--margin-vertical-small);
}
.filters_item__1yZH- {
    display: inline-block;
    width: auto;
    height: auto;
    background: var(--color-white);
    padding: var(--padding-block-small);
    border-radius: var(--border-radius);
    margin-right: var(--margin-vertical-med);
}

.filters_chosenFilterBlock__1_BnH p {
    font-size: var(--font-size-med);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-light);
    color: var(--color-dark-shades-2);
    display: inline-block;
    margin: 0;
}

.filters_chosenFilterBlock__1_BnH .filters_value__3ZtQ8 {
    font-weight: var(--font-weight-med);
    font-size: var(--font-size-large);
    margin-right: 10px;
    margin-left: 5px;
}

.filters_chosenFilterBlock__1_BnH .filters_icon__WFV5h * {
    width: var(--icon-size-small);
    height: var(--icon-size-small);
    fill: var(--color-dark-shades-1);
    margin-bottom: -2px;
}

.filters_chosenFilterBlock__1_BnH .filters_deleteAll__3-p58 {
    width: auto;
    background: transparent;
    border: none;
    margin-top: var(--margin-vertical-med);
    margin-left: var(--margin-vertical-small);
    color: var(--color-dark-shades-2);
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-med);
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .filters_filterContainer__RHY-u {
        top: 10px;
        width: calc(100% - 20px);
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        z-index: 4;
    }
    
    .filters_filterBlock__3AZPN {
        width: 100%;
        height: 60px;
        border-radius: var(--border-radius);
        padding-left: 5px;
    }
    
    .filters_filter__2Qvc8 {
        width: calc( 100%/3 - 10px );
        padding: 0 5px 0 5px;
    }
    
    .filters_selectInput__2krmf .filters_filterLabel__16hlE {
        text-transform: uppercase;
        font-family: var(--font-header);
        font-size: 10px;
        letter-spacing: 0.75px;
    }
    
    .filters_selectInput__2krmf .filters_filterValue__XocW0 {
        font-size: 13px;
        height: 20px;
        letter-spacing: 0.25px;
        font-weight: var(--font-weight-regular);
    }

    .filters_options__12jbY {
        position: fixed;
        top: 100px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: calc(100% - 20px);
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: var(--border-radius);
    }
    
    .filters_options__12jbY div {
        padding: 10px;
        font-size: var(--font-size-small-phone);
    }

    .filters_searchFilter__Lp0QD {
        width: 50px;
        height: 50px;
        right: 5px;
        border-radius: var(--border-radius);
    }

    .filters_filterIcon__FyfKr svg {
        top: 13px;
        right: 14px;
        width: var(--icon-size-large);
        height: var(--icon-size-large);
        border-radius: var(--border-radius);
    }

    .filters_searchInputBlock__1-mWZ {
        width: calc(100% - 20px);
        height: 60px;
        top: 20px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        padding: 5px 10px 5px 10px;
        background: var(--color-white);
    }

    .filters_searchInputBlock__1-mWZ div {
        display: inline-block;
        margin-top: 5px;
    }

    .filters_searchInputBlock__1-mWZ input {
        font-size: var(--font-size-small-phone);
        width: calc(100% - 60px);
        margin-top: 15px;
    }

    .filters_searchInputBlock__1-mWZ button {
        font-size: var(--font-size-small-phone);
        width: 50px;
        height: 50px;
        right: 5px;
        top: 5px;
        border-radius: var(--border-radius);
    }

    .filters_searchInputBlock__1-mWZ svg {
        top: 15px;
        left: 15px;
        width: var(--icon-size-large);
        height: var(--icon-size-large);
    }
    

  }
.navbar_filter__rBpCd {
    width: 70px;
    height: 70px;
    background: var(--color-white);
    border-radius: 100%;
    box-shadow: var(--shadow);
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 10;
}

.navbar_filter__rBpCd svg {
    width: 40px;
    height: 40px;
    top: 15px;
    left: 15px;
    text-align: center;
    position: absolute;
}

.navbar_menu__2jRBm {
    width: 220px;
    height: auto;
    position: fixed;
    right: 50px;
    bottom: 200px;
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius-large);
    padding: var(--padding-block-regular);
    box-sizing: border-box;
    z-index: 10;
    text-align: left;

    display: none;
}

.navbar_open__46WT3 {
    display: block;
}

.navbar_menu__2jRBm div {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 50px;
    text-align: left;
}

.navbar_menu__2jRBm div button {
    border: none;
    background: transparent;
    font-size: var(--font-size-large2);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-header);
    padding: 0;
    margin: 0;
    cursor: pointer;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .navbar_filter__rBpCd {
        width: 50px;
        height: 50px;
        right: 10px;
        bottom: 20px;
    }

    .navbar_filter__rBpCd svg {
        width: 35px;
        height: 35px;
        top: 7.5px;
        left: 7.5px;
    }

    .navbar_menu__2jRBm {
        width: 90%;
        right: 5%;
        bottom: 90px;
    }

    .navbar_menu__2jRBm div button {
        font-size: var(--font-size-small);
    }
      
  }
.login_container__2YqWV {
    width: 100%;
    height: 100%;
    position: relative;
}

.login_loginFormContainer__2r_8I {
    background: var(--color-white);
    width: 90%;
    max-width: 400px;
    height: auto;
    margin: var(--margin-center);
    padding: var(--margin-vertical-large);
    border: var(--border-light);
    border-radius: var(--border-radius-large);
    text-align: center;
    
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-shadow: var(--shadow);
}

.login_loading__3wePT {
    opacity: 0.5;
}

.login_mainHeader__2zEgN {
    font-size: var(--font-size-large2);
    font-family: var(--font-header);
    font-weight: var(--font-weight-med);
    color: var(--color-dark-shades-2);
}

.login_logo__3wGMJ {
    font-size: var(--font-size-large2);
    font-family: var(--font-header);
    font-weight: var(--font-weight-med);
    color: var(--color-brand-1);
    margin-top: var(--margin-vertical-med);
    margin-bottom: var(--margin-vertical-med);
}

.login_inputContainer__3dc46 {
    width: 100%;
    text-align: left;
    margin-top: var(--margin-vertical-large);
}

.login_loginFormContainer__2r_8I label {
    font-size: var(--font-size-med);
    font-family: var(--font-header);
    color: var(--color-dark-shades-1);
    font-weight: var(--font-weight-med);
}

.login_loginFormContainer__2r_8I input {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-size: var(--font-size-med);
    font-family: var(--font-basic);
    color: var(--color-dark-shades-1);
    margin-top: var(--margin-vertical-small);
    border: none;
    border-radius: 7px;
    background: var(--color-light-shades-1);
}
.login_loginFormContainer__2r_8I input:focus {
    outline: 2px solid var(--color-brand-1);
}


.login_loginFormContainer__2r_8I button {
    width: 100%;
    height: 60px;
    font-size: var(--font-size-large);
    background: var(--color-brand-1);
    font-family: var(--font-basic);
    color: var(--color-light-shades-1);
    border: none;
    border-radius: 7px;
    margin-top: var(--margin-vertical-med);
    cursor: pointer;
}
@-webkit-keyframes notifications_trans__Pl3Zp {
    from {top: -40px; opacity: .25};
    to {top: 60px; opacity: 1};
}

@keyframes notifications_trans__Pl3Zp {
    from {top: -40px; opacity: .25};
    to {top: 60px; opacity: 1};
}

.notifications_notificationContainer__3dfDN {
    width: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    text-align: center;
    z-index: 99999;
}

.notifications_notification__33nwp {
    width: auto;
    max-width: 90%;
    display: inline-block;
    padding: var(--padding-block-regular);
    box-sizing: border-box;
    margin: 0 auto;
    background: var(--color-white);
    border: none;
    border-radius: var(--border-radius-large);
    box-shadow: var(--shadow);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-basic);
    color: var(--color-danger);
    text-align: center;
    -webkit-animation-name: 'trans';
            animation-name: 'trans';
}

.notifications_notification__33nwp p {
    padding: 0;
    margin: 0;
}
.admin_siteContainer__2f57- {
    width: 100%;
    margin: var(--margin-center);
    padding: 60px;
    box-sizing: border-box;
}

.admin_input__BQeHo {
    width:100%;
    max-width: 500px;
    font-family: var(--font-basic);
    font-size:var(--font-size-med);
    margin-top:20px;
    display: flex;
    flex-wrap:wrap;
    width:100%;
}

.admin_input__BQeHo * {
    width:100%;
}

.admin_input__BQeHo input{
    padding: 30px 35px;
    border-radius:5px;
    border:none;
    width:100%;
    margin:0px;
    font-size: var(--font-size-large);
    font-family: var(--font-header);
    box-shadow: var(--shadow);
}

.admin_input__BQeHo p {
    margin-top: 0px;
}

.admin_highlightInput__13Bmy {
    background-color:var(--color-dark-accent-4);
    padding:10px;
    border-radius:5px;
    box-shadow: var(--shadow);
    font-size: var(--font-size-large);
    font-family: var(--font-basic);
}

.admin_highlightInput__13Bmy h1 {
    font-size: var(--font-size-med);
    font-weight: 400;
}

.admin_highlightInput__13Bmy p {
    margin-top:20px;
    font-size: 18px;
    color:var(--color-dark-shades-1);
}


.admin_listInput__3cWhI {
    width:100%;
    max-width: 500px;
    font-family: var(--font-basic);
    font-size:var(--font-size-med);
    margin-top:60px;
    
}

.admin_select__1rMk- {
    width:80%;
    max-width: 400px;
}

.admin_button__1V0Q5 {
    margin:60px 0px;
    font-family: var(--font-basic);
    font-weight: var(--font-weight-med);
    font-size: var(--font-size-small);
    border: none;
    cursor: pointer;
    padding: var(--padding-block-regular);
    min-width:250px;
    background: var(--color-brand-1);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.admin_halfBlock__1BeRc {
    display: flex;
    justify-content: space-between;
    width:100%;
}

.admin_halfBlock__1BeRc input {
    width:32%;
}

.admin_listItem__3gWGh {
    display: block;
    position: relative;
    width: 100%;
    background: var(--color-white);
    box-sizing: border-box;
    border-radius: var(--border-radius);
    border: var(--border-light);
    padding: var(--padding-block-regular);
}

.admin_listItem__3gWGh h3 {
    width: 100%;
    font-size: var(--font-basic);
    font-weight: var(--font-weight-regular);
    margin: 0;
    box-sizing: border-box;
    padding-right: 40px;
}

.admin_listItem__3gWGh div {
    position: absolute;
    right: 25px;
    top: 22px;
    cursor: pointer;
    margin-left: 25px;
}

.admin_listItem__3gWGh div svg {
    width: 16px;
    height: 16px;
}

.admin_listItem__3gWGh div svg * {
    fill: var(--color-dark-shades-2);
}

.admin_icon__7k1K9 {
    font-size:32px;
}







.admin_adminContainer__rzkDV {
    width: 100%;
    display: inline-flex;
    height: auto;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background: var(--color-light-shades-1);
}

.admin_navbar__2Jzoz {
    top: 0;
    left: 0;
    position: sticky;
    position: -webkit-sticky;
    display: inline-block;
    width: 260px;
    height: 100vh;
    background: var(--color-white);
    padding: var(--body-padding);
    padding-top: 80px;
    box-sizing: border-box;
}

.admin_navbar__2Jzoz div {
    display: block;
    width: 100%;
    margin-top: var(--margin-vertical-large);
}

.admin_navbar__2Jzoz div a {
    font-family: var(--font-header);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-med);
    color: var(--color-dark-shades-1);
    text-decoration: none;
}

.admin_navbar__2Jzoz .admin_active__5Ldtq a {
    color: var(--color-brand-1) !important;
}

.admin_navbar__2Jzoz svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-bottom: -2px;
}

.admin_navbar__2Jzoz .admin_separator__145hm {
    width: 100%;
    height: 1px;
    display: block;
    border-bottom: 1px solid var(--color-dark-shades-4);
}

.admin_dataContainer__4dyjJ {
    width: calc(100% - 260px);
    max-width: 1000px;
    display: block;
    height: 100vh;
    padding: 0 40px 40px 100px;
    box-sizing: border-box;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.admin_dataContainer__4dyjJ::-webkit-scrollbar {
    display: none;
  }

.admin_titleContainer__pM6S5 {
    width: 100%;
    margin-top: 120px;
    margin-bottom: 60px;
    position: relative;
}

.admin_titleContainer__pM6S5 h3 {
    font-family: var(--font-header);
    font-size: var(--font-size-large3);
    color: var(--color-dark-shades-1);
    font-weight: var(--font-weight-med);
    letter-spacing: 1px;
}

.admin_titleContainer__pM6S5 button {
    position: absolute;
    padding-left: 60px;
    padding-right: 20px;
    right: 0;
    top: 0;
    height: 45px;
    background: var(--color-brand-1);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    
    font-family: var(--font-header);
    color: var(--color-white);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-light);
}

.admin_titleContainer__pM6S5 button svg {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
}

.admin_tile__3yCjC {
    width: 100%;
    min-height: 70px;
    padding: var(--padding-block-regular);
    margin-top: var(--margin-vertical-small);
    background: var(--color-white);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    display: block;
    position: relative;
}

.admin_siteTile__3LMeJ {
    padding-left: 120px;
}

.admin_tile__3yCjC p {
    width: 250px;
    display: inline-block;
    padding: 0;
    margin: 0;
    font-family: var(--font-basic);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-1);
}

.admin_tile__3yCjC .admin_header__FwGa3 {
    color: var(--color-brand-1);
}

.admin_tile__3yCjC button {
    position: absolute;
    right: 10px;
    top: 5px;
    width: 60px;
    height: 60px;
    background: var(--color-light-shades-1);
    border: var(--border-light);
    border-radius: var(--border-radius);
    cursor: pointer;
}

.admin_tile__3yCjC button svg {
    width: var(--icon-size-mid);
    height: var(--icon-size-mid)
}

.admin_tile__3yCjC .admin_deleteButton__wV23S {
    right: 80px;
}

.admin_tile__3yCjC .admin_checkboxContainer__1E5Xs {
    width: 70px;
    height: 100%;
    border-right: 1px solid var(--color-dark-shades-4);
    position: absolute;
    top: 0;
    left: 0;
}

.admin_tile__3yCjC .admin_checkboxContainer__1E5Xs .admin_checkbox__3kxDP {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 20px;
    left: 19px;
    background: var(--color-light-shades-1);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-dark-shades-4);
    cursor: pointer;
}

.admin_tile__3yCjC .admin_checkboxContainer__1E5Xs .admin_checkbox__3kxDP svg {
    display: none;
}

.admin_checked__2_vSf {
    background: var(--color-brand-1) !important;
}

.admin_checked__2_vSf svg {
    top: 5px;
    left: 6px;
    width: var(--icon-size-mid);
    height: var(--icon-size-mid);
    position: absolute;
    display: block !important;
}

.admin_chooseAllContainer__3eIxf {
    width: 100%;
    padding-left: 19px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.admin_chooseAllContainer__3eIxf .admin_checkbox__3kxDP {
    width: 30px;
    height: 30px;
    background: var(--color-white);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-dark-shades-4);
    cursor: pointer;
    display: inline-block;
    margin-top: -25px;
    position: relative;
}

.admin_chooseAllContainer__3eIxf p {
    font-family: var(--font-basic);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-light);
    color: var(--color-dark-shades-1);
    display: inline-block;
    margin: 0;
    margin-left: 10px;
    vertical-align: bottom;
    margin-bottom: 5px;
}

.admin_chooseAllContainer__3eIxf button {
    font-family: var(--font-basic);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    color: var(--color-danger);
    display: inline-block;
    margin-left: 30px;
    vertical-align: bottom;
    margin-bottom: 5px;
    border: none;
    background: transparent;
    cursor: pointer;
}

.admin_noSitesDisclaimer__1z-Nf {
    margin-top: 100px;
    font-family: var(--font-basic);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-1);
}
