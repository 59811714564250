@import "../config.css";

.filter {
    width: 70px;
    height: 70px;
    background: var(--color-white);
    border-radius: 100%;
    box-shadow: var(--shadow);
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 10;
}

.filter svg {
    width: 40px;
    height: 40px;
    top: 15px;
    left: 15px;
    text-align: center;
    position: absolute;
}

.menu {
    width: 220px;
    height: auto;
    position: fixed;
    right: 50px;
    bottom: 200px;
    background: var(--color-white);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius-large);
    padding: var(--padding-block-regular);
    box-sizing: border-box;
    z-index: 10;
    text-align: left;

    display: none;
}

.open {
    display: block;
}

.menu div {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 50px;
    text-align: left;
}

.menu div button {
    border: none;
    background: transparent;
    font-size: var(--font-size-large2);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-header);
    padding: 0;
    margin: 0;
    cursor: pointer;
}

@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .filter {
        width: 50px;
        height: 50px;
        right: 10px;
        bottom: 20px;
    }

    .filter svg {
        width: 35px;
        height: 35px;
        top: 7.5px;
        left: 7.5px;
    }

    .menu {
        width: 90%;
        right: 5%;
        bottom: 90px;
    }

    .menu div button {
        font-size: var(--font-size-small);
    }
      
  }