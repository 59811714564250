@import "./config.css";

.mapContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.mapBlock {
  position: fixed;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  top: 0;
  left: 0;
  background: var(--color-white);
  z-index: 2;
}

.inspectPageMapBlock > div {
  width: 100% !important;
}

.inspectPageMapBlock .siteName {
  position: absolute;
  top: 70px;
  left: 110px;
  font-weight: var(--font-weight-bold);
  color: var(--color-dark-shades-2);
  text-shadow: 0 2px 4px var(--color-light-shades-3);
  font-size: 36px;
  font-family: var(--font-header);
  z-index: 4;
  margin: 0;
}

.goBackBlock {
  position: absolute;
  top: 55px;
  left: 40px;
  z-index: 10;
}

.goBackBlock .backButton {
  background-color: var(--color-white);
  border-radius: 100%;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  z-index: 4;
}

.goBackBlock h3 {
  display: inline-block;
  margin-left: 10px;
  font-weight: var(--font-weight-bold);
  color: var(--color-dark-shades-2);
  text-shadow: 0 2px 4px var(--color-light-shades-3);
  font-size: 26px;
  font-family: var(--font-header);
}

.marker {
  position: absolute;
  border-radius: 50%;
  background-image: url("http:/icons/marker-screen.svg");
  width: 60px;
  height: 60px;
  background-size: 100%;
  z-index: 3;
}

.marker h3 {
  font-size: var(--font-size-med);
  font-family: var(--font-basic);
  font-weight: var(--font-weight-med);
  color: var(--color-dark-shades-2);
  text-shadow: var(--text-shadow);
  margin-top: 60px;
  text-align: center;
}

.popupContainer {
  z-index: 5;
  border-radius: var(--border-radius);
}

.popup {
  width: auto;
  max-width: 250px;
  text-align: left;
  position: relative;
  padding: 10px 40px 10px 40px;
}

.popup * {
  padding: 0;
  margin: 0;
}

.popup h3 {
  font-size: 20px;
  color: var(--color-dark-shades-2);
  font-family: var(--font-header);
  font-weight: var(--font-weight-bold);
  margin-bottom: 30px;
}

.popup div {
  display: block;
  width: auto;
  margin-bottom: 20px;
}

.popup div span {
  display: inline-block;
  margin-right: 15px;
}

.popup div span svg {
  width: 20px;
  height: 20px;
  margin-bottom: -5px;
}

.popup div p {
  display: inline;
  font-family: var(--font-basic);
  font-size: var(--font-size-med);
  color: var(--color-dark-shades-2);
}

.popup button {
  width: 100%;
  font-family: var(--font-basic);
  font-size: var(--font-size-med);
  color: var(--color-light-shades-1);
  background: var(--color-brand-1);
  padding: var(--padding-block-small);
  border: none;
  border-radius: var(--border-radius);
  margin-top: 10px;
}

.hidden {
  display: none;
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .goBackBlock {
      top: 25px;
      left: 20px;
    }
    .goBackBlock h3  {
      font-size: var(--font-size-large3);
    }

    .goBackBlock .backButton {
      font-size: var(--font-size-large);
      width: 45px;
      height: 45px;
    }
}