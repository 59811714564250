@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Urbanist:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");

body {
  padding: 0;
  margin: 0;
}

:root {
  --color-dark-shades-1: rgba(34, 33, 47, 1);
  --color-dark-shades-2: rgba(34, 33, 47, 0.75);
  --color-dark-shades-3: rgba(34, 33, 47, 0.5);
  --color-dark-shades-4: rgba(34, 33, 47, 0.25);

  --color-light-shades-1: rgba(240, 244, 245, 1);
  --color-light-shades-2: rgba(240, 244, 245, 0.75);
  --color-light-shades-3: rgba(240, 244, 245, 0.5);
  --color-light-shades-4: rgba(240, 244, 245, 0.25);

  --color-brand-1: rgba(46, 45, 166, 1);
  --color-brand-2: rgba(46, 45, 166, 0.75);
  --color-brand-3: rgba(46, 45, 166, 0.5);
  --color-brand-4: rgba(46, 45, 166, 0.25);

  --color-light-accent-1: rgba(148, 172, 211, 1);
  --color-light-accent-2: rgba(148, 172, 211, 0.75);
  --color-light-accent-3: rgba(148, 172, 211, 0.5);
  --color-light-accent-4: rgba(148, 172, 211, 0.25);

  --color-dark-accent-1: rgba(101, 122, 183, 1);
  --color-dark-accent-2: rgba(101, 122, 183, 0.75);
  --color-dark-accent-3: rgba(101, 122, 183, 0.5);
  --color-dark-accent-4: rgba(101, 122, 183, 0.25);

  --color-white: #fff;
  --color-background: rgb(240, 244, 245);
  --color-background-dark: rgb(229, 233, 236);
  --color-warning: #c08432;
  --color-danger: #A02920;
  --color-success: #43946a;
  --color-shadow: rgba(34, 33, 47, 0.1);

  --shadow: 0 6px 20px 0 rgba(34, 33, 47, 0.1);
  --border-radius: 3px;
  --border-radius-large: 15px;
  --border-light: 1px solid rgba(240, 244, 245, 1);

  --body-padding: 0 40px 0 40px;
  --padding-block-regular: 20px 30px 20px 30px;
  --padding-block-small: 10px 15px 10px 15px;
  --padding-horizontal-large: 40px;
  --padding-horizontal-regular: 30px;
  --padding-horizontal-small: 15px;
  --margin-vertical-small: 10px;
  --margin-vertical-med: 30px;
  --margin-vertical-large: 50px;
  --margin-center: 0 auto;

  --font-basic: "Red Hat Text", sans;
  --font-header: "Urbanist", sans-serif;
  --font-size-xlarge: 28px;
  --font-size-large3: 26px;
  --font-size-large2: 24px;
  --font-size-large: 22px;
  --font-size-med: 20px;
  --font-size-small: 18px;
  --font-size-small-phone: 14px;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-med: 500;
  --font-weight-bold: 600;
  --letter-spacing-small: 0.1px;
  --text-shadow: 2px 2px 4px rgba(34, 33, 47, 0.2);

  --icon-size-small: 14px;
  --icon-size-mid: 18px;
  --icon-size-large: 24px;
}
