@import '../config.css';

@keyframes inspectAnim {
    from {bottom: -500px}
    to {bottom: 0}
}

.siteContainer {
    width: 100vw;
    height: 70vh;
    overflow-y: scroll;
    background: var(--color-background);
    z-index: 10;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -6px 4px 0 var(--color-shadow);
    animation: inspectAnim 0.5s;
}

.dataContainer {
    width: 100%;
    padding: var(--padding-block-regular);
    padding-top: 80px;
    box-sizing: border-box;
}

.phase {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-block-regular);
    font-family: var(--font-basic);
    border-radius: var(--border-radius);
    position: relative;
}

.activePhase {
    background-color: var(--color-white);
    box-shadow: var(--shadow);
}

.allCompletedPhase {
    background-color: var(--color-brand-1);
}

.allCompletedPhase * {
    color: var(--color-light-shades-1) !important;
}


.phase .name {
    font-family: var(--font-header);
    font-size: var(--font-size-large3);
    font-weight: var(--font-weight-med);
    color: var(--color-brand-1);
}

.phase  .description {
    font-size: var(--font-size-small);
    color: var(--color-dark-shades-2);
    font-weight: var(--font-weight-med);
}

.actionButton {
    padding: 0;
    background: transparent;
    color: var(--color-dark-shades-2);
    font-size: var(--font-size-med);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-med);
    border: none;
    cursor: pointer;
}

.activePhase .actionButton {
    padding: var(--padding-block-regular);
    background: var(--color-brand-1);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.actionButton svg {
    width: 20px;
    margin-right: 10px;
    margin-bottom: -2px;
    fill: var(--color-light-shades-1);
}

.commentsContainer {
    display: flex;
    flex-direction: column-reverse;
}

.commentWrapper {
    width: 100%;
    display: block;
    font-family: var(--font-basic);
    color: var(--color-dark-shades-1);
    font-size: var(--font-size-med);
}

.comment {
    width: auto;
    display: inline-block;
    background-color:var(--color-white);
    box-shadow: var(--shadow);
    padding: var(--padding-block-small);
    box-sizing: border-box;
    margin-bottom: var(--margin-vertical-med);
    border-radius: var(--border-radius);
}

.commentWrapper .sender {
    font-family: var(--font-header);
    font-weight: var(--font-weight-med);
    color: var(--color-dark-shades-2);
}

.comment .text {
    display: block;
    width: auto;
}

.comment .date {
    color: var(--color-dark-shades-2);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-med);
}

.commentInfoTitle {
    font-family: var(--font-header);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-large);
    margin-bottom: var(--margin-vertical-med);
    margin-top: calc(2*var(--margin-vertical-large));
    color: var(--color-dark-shades-2);
}

.textAreaContainer {
    width:100%;
    background-color:var(--color-white);
    box-shadow: var(--shadow);
    padding: var(--padding-block-regular);
    padding-left: 40px;
    box-sizing: border-box;
    margin-bottom: var(--margin-vertical-med);
    border-radius: var(--border-radius);
    position: relative;
    height: 80px;
}

textarea {
    border:none;
    width: calc(100% - 60px);
    height: 50px;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    font-size: var(--font-size-large);
    font-family: var(--font-header);
    resize: none;
}

textarea:focus {
    outline: none;
}

.textAreaContainer button {
    border: none;
    color: var(--color-light-shades-1);
    width: 60px;
    height: 60px;
    top: 10px;
    right: 20px;
    position: absolute;
    border-radius: var(--border-radius);
    background-color: var(--color-brand-1);
    cursor: pointer;
}

.textAreaContainer button svg {
    fill: var(--color-light-shades-1);
    width: 28px;
    height: 28px;
}

.tabButtons {
    width: 100%;
    margin-bottom: var(--margin-vertical-med);
}

.tabButtons button {
    width: auto;
    padding: 0 30px 0 30px;
    box-sizing: border-box;
    height: 50px;
    border-radius: 50px;
    background: transparent;
    color: var(--color-dark-shades-2);
    border: none;
    margin-right: var(--margin-vertical-large);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-med);
    font-family: var(--font-basic);
    cursor: pointer;
}

.tabButtons button * {
    display: inline;
    padding: 0;
}

.tabButtons button span svg {
    width: 20px;
    height: 20px;
    margin-bottom: -5px;
    margin-right: 10px;
}

.tabButtons .active {
    background: var(--color-dark-shades-2);
    color: var(--color-light-shades-1);
}

.tabButtons span * {
    fill: var(--color-dark-shades-1);
}

.tabButtons .active span * {
    fill: var(--color-white);
}

.expandButton {
    width: auto;
    position: absolute;
    top: -25px;
    left: 40px;
    height: 50px;
    padding: var(--padding-block-small);
    border-radius: var(--border-radius);
    background: var(--color-white);
    color: var(--color-brand-1);
    border: none;
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-basic);
    box-shadow: var(--shadow);
    cursor: pointer;
}

.expandButton * {
    display: inline;
}

.expandButton svg {
    width: 16px;
    height: 16px;
    margin-bottom: -5px;
    margin-right: 20px;
}

.blockedContainer {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    box-sizing: border-box;
    display: block;
    margin-top: 40px;
    background: var(--color-white);
    text-align: center;
}

.blockedContainer p {
    font-size: var(--font-size-small);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-1);
    text-align: center;
    display: block;
}

.blockedContainer button {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-top: 40px;
    font-size: var(--font-size-small-phone);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-med);
    border: none;
    cursor: pointer;
    height: 50px;
    background: var(--color-brand-1);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.blockedContainer svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
}
.blockedContainer svg * {
    fill: var(--color-danger);
}

.markBlockedContainer {
    width: calc(100% - 20px);
    margin: 0 auto;
    display: block;
    margin-top: 40px;
}

.markBlockedContainer button {
    padding: 0;
    font-size: var(--font-size-small);
    font-size: var(--font-size-small-phone);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-regular);
    border: none;
    cursor: pointer;
    
    width: 200px;
    height: 50px;
    background: var(--color-dark-shades-2);
    color: var(--color-light-shades-1);
    border-radius: var(--border-radius);
}

.markBlockedContainer button * {
    display: inline;
}

.markBlockedContainer button svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-bottom: -4px;
}

.markBlockedContainer p {
    padding: 0;
    font-size: var(--font-size-small-phone);
    font-family: var(--font-basic);
    font-weight: var(--font-weight-regular);
    color: var(--color-dark-shades-2);
}


@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .siteContainer {
        width: 100vw;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .dataContainer {
        padding: 0;
    }

    .expandButton {
        display: none;
    }

    .tabButtons button {
        width: 50%;
        padding: 0;
        box-sizing: border-box;
        border-radius: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        margin: 0;
        font-size: var(--font-size-small-phone);
    }
    .tabButtons .left {
        border-top-right-radius: 0;
    }
    .tabButtons .right {
        border-top-left-radius: 0;
    }
    .phase {
        padding: var(--padding-block-small);
        border-radius: 0;
    }
    .phase .name {
        font-size: var(--font-size-large);
    }
    .phase  .description {
        font-size: var(--font-size-small-phone);
    }
    .actionButton {
        font-size: var(--font-size-small-phone);
        position: absolute;
        top: 20px;
        right: 10px;
    }
    .activePhase .actionButton {
        padding: var(--padding-block-regular);
        width: 100%;
        position: static;
    }
    .actionButton svg {
        width: 15px;
        margin-right: 5px;
        margin-bottom: -3px;
    }
    .commentAreaContainer {
        width: 100%;
        padding: var(--padding-block-small);
        box-sizing: border-box;
    }
    .commentInfoTitle {
        font-size: var(--font-size-small);
        margin-top: var(--margin-vertical-large);
    }
    .commentWrapper {
        font-size: var(--font-size-small);
    }
    .comment {
        width: 100%;
        display: block;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
    .comment .date {
        font-size: var(--font-size-small-phone);
    }
    .textAreaContainer {
        padding: var(--padding-block-small);
        padding-left: 10px;
        height: 60px;
    }
    
    textarea {
        width: calc(100% - 40px);
        height: 40px;
        margin-top: 10px;
        font-size: 16px;
    }
    
    textarea:focus {
        outline: none;
    }
    
    .textAreaContainer button {
        border: none;
        color: var(--color-light-shades-1);
        width: 60px;
        height: 60px;
        top: 10px;
        right: 20px;
        position: absolute;
        border-radius: var(--border-radius);
        background-color: var(--color-brand-1);
        cursor: pointer;
    }

    .textAreaContainer button {
        width: 60px;
        height: 60px;
        top: 0;
        right: 0;
    }

    .textAreaContainer button svg {
        width: 20px;
        height: 20px;
    }

    .blockedContainer {
        max-width: none;
    }

    .blockedContainer p {
        font-size: var(--font-size-small-phone);
    }
    
}