@import './config.css';

@keyframes trans {
    from {top: -40px; opacity: .25};
    to {top: 60px; opacity: 1};
}

.notificationContainer {
    width: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    text-align: center;
    z-index: 99999;
}

.notification {
    width: auto;
    max-width: 90%;
    display: inline-block;
    padding: var(--padding-block-regular);
    box-sizing: border-box;
    margin: 0 auto;
    background: var(--color-white);
    border: none;
    border-radius: var(--border-radius-large);
    box-shadow: var(--shadow);
    font-size: var(--font-size-med);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-basic);
    color: var(--color-danger);
    text-align: center;
    animation-name: 'trans';
}

.notification p {
    padding: 0;
    margin: 0;
}